import React from "react"
import { appName } from "config"
import Link from "components/link"
import NavbarDropdown from "components/navbar-dropdown"
import HamburgerMenu from "components/hamburger-menu"

const Navbar = props => (
  <header className="z-10 py-2 bg-white shadow-md dark:bg-gray-800 mb-3">
    <div className="flex items-center justify-between h-full px-4 md:px-10 mx-auto text-primary-600 dark:text-primary-300">
      <HamburgerMenu />
      <Link
        clearClass
        className="lg:mr-32 inline-block whitespace-nowrap text-base text-black uppercase font-bold px-0"
        to="/"
      >
        <span className="inline md:hidden">{appName}</span>
      </Link>
      <ul className="flex items-center flex-shrink-0 space-x-6">
        {/* Profile menu */}
        <li className="relative">
          <NavbarDropdown />
        </li>
      </ul>
    </div>
  </header>
)

export default Navbar
