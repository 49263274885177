import React from "react"

const CardLayout = ({ children }) => (
  <div className="w-full h-screen flex items-center justify-center">
    <div className="big-card relative">
      <div className="relative rounded-md shadow-md bg-white p-4">
        {children}
      </div>
    </div>
  </div>
)

export default CardLayout
