import React, { useState } from "react"

const withState = (C, initial) => props => {
  const [state, setState] = useState(initial || {})
  return (
    <C
      {...props}
      state={x =>
        x === undefined ? state : setState(prev => ({ ...prev, ...x })) || state
      }
      stateObject={state}
    />
  )
}

export default withState
