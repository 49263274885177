import React from "react"
import OutsideClickHandler from "react-outside-click-handler"
import withState from "hoc/withState"
import RoleFilter from "components/role-filter"
import Link from "components/link"
import { menu } from "config"
import incId from "helpers/incId"

const HamburgerMenu = ({ state }) => (
  <div className="relativex">
    {/* Mobile hamburger */}
    <button
      className="h-7 w-7 p-1 mr-5 -ml-1 rounded-md md:hidden focus:outline-none focus:shadow-outline-primary"
      aria-label="Menu"
      onClick={e => {
        e.preventDefault()
        state({ open: !state().open })
      }}
      id={`nav-burger-${incId()}`}
    >
      <svg
        className="w-6 h-6"
        aria-hidden="true"
        fill="black"
        viewBox="0 0 20 20"
      >
        <path
          fillRule="evenodd"
          d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
          clipRule="evenodd"
        />
      </svg>
    </button>
    {state().open && (
      <OutsideClickHandler
        onOutsideClick={() => {
          state({ open: !state().open })
        }}
      >
        <ul
          className="dropdown-menu dropdown-menu-right shadow absolute left-0 w-screen bg-white rounded-md flex flex-col p-2 z-10"
          aria-labelledby={`nav-burger-${incId() - 1}`}
        >
          {menu.map((item, i) => (
            <RoleFilter role={item.role} key={i}>
              <li className="items-center list-none">
                <a
                  href={item.to}
                  className="dropdown-item inline-flex items-center w-full px-2 py-1 text-sm transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200 text-black"
                >
                  <item.icon className="mr-2 text-sm" />
                  {item.name}
                </a>
              </li>
            </RoleFilter>
          ))}
        </ul>
      </OutsideClickHandler>
    )}
  </div>
)

export default withState(HamburgerMenu)
