import React from "react"
import { withMe } from "context/me"

const RoleFilter = ({ me, role, children, disabled }) =>
  disabled ||
  !role ||
  me?.roles?.find(f => (Array.isArray(role) ? role : [role]).includes(f)) ? (
    <>{children}</>
  ) : (
    <></>
  )

export default withMe(RoleFilter)
