import React from "react"
import { colors } from "config"
import { withMe } from "context/me"
import { hash } from "helpers/utils"
import colorHash from "helpers/color-hash"
import classnames from "classnames"

const l = x => x?.[0] || " "
const letters = ({ first_name, last_name }) => `${l(first_name)}${l(last_name)}`

const Avatar = ({ me, size = "2.2rem", className, data }) => (
  <div
    className={classnames(
      "align-middle rounded-full focus:shadow-outline-primary focus:outline-none cursor-pointer",
      className,
      `bg-${colorHash(
        `${(data || me)?.first_name} ${(data || me)?.last_name}`
      )}-700`
    )}
    style={{
      height: size,
      width: size,
    }}
  >
    <div
      style={{
        fontSize: size,
      }}
    >
      <div
        style={{
          fontSize: "0.435em",
          top: "1.5px",
          left: "0",
          right: "0",
          bottom: "0",
        }}
        className="absolute flex justify-center items-center text-white"
      >
        {letters(data || me)}
      </div>
    </div>
  </div>
)

export default withMe(Avatar)
