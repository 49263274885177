import React from "react"
import Loading from "components/loading"
import { AiOutlineHome } from "react-icons/ai"

const Index = props => (
  <div className="text-center py-3">
    <h1 className="text-3xl font-bold text-primary-400">Ошибка 404</h1>
    <h1 className="text-xl font-medium py-3 text-gray-500">
      страница не найдена
    </h1>
    <p className="text-base pb-4 px-12 font-medium">
      Страница, которую вы ищете, не существует. Возможно, она была перемещена
      или удалена.
    </p>
    <div className="w-full flex justify-center">
      <a
        className="mt-2 px-5 py-2 bg-primary-600 text-white font-medium rounded hover:bg-primary-500 focus:outline-none focus:bg-primary-500 flex items-center justify-center"
        href="/"
      >
        <AiOutlineHome className="mr-1 mb-0.5" />
        <span>На главную</span>
      </a>
    </div>
  </div>
)

Index.title = "Ошибка 404"

export default Index
