import * as ri from "react-icons/ri"
import { MdLiveTv } from "react-icons/md"
import { RiBarcodeBoxLine, RiShoppingCartLine } from "react-icons/ri"
import { HiOutlineBriefcase } from "react-icons/hi"
import { AiOutlineHome } from "react-icons/ai"
import "moment/locale/ru"

export const appName = process.env.REACT_APP_SITE_NAME

export const locale = "ru-RU"

export const currency = "₽"

export const colors = [
  "red",
  "yellow",
  "green",
  "blue",
  "indigo",
  "primary",
  "pink",
]

export const navSettings = {
  openMenuOnHover: true,
  // style can be "light" or "heavy"
  style: "heavy",
  sticky: true,
  container: false,
}

export const accentColor = "#6366f1" // **TODO** use colors from scss

export const menu = [
  {
    name: "Главная",
    icon: AiOutlineHome,
    to: "/",
  },
  {
    name: "Пользователи",
    icon: ri.RiGroupLine,
    to: "/admin/users",
    role: "admin",
  },
].concat(
  ["client", "employee"].flatMap(role => [
    {
      name: "Брифы",
      icon: HiOutlineBriefcase,
      to: `/${role}/briefs`,
      role,
    },
  ])
)

export const roleOptions = [
  {
    value: "client",
    label: "Клиент",
  },
  {
    value: "employee",
    label: "Сотрудник",
  },
]

export const paymentOptions = [
  {
    value: "cash",
    label: "наличные",
  },
  {
    value: "card",
    label: "карта",
  },
]

export const deliveryOptions = [
  {
    value: "pickup",
    label: "самовывоз",
  },
  {
    value: "courier",
    label: "доставка",
  },
]

export const statusOptions = [
  {
    label: "Новый",
    value: "new",
  },
  {
    label: "В обработке",
    value: "processing",
  },
  {
    label: "Отменен",
    value: "canceled",
  },
  {
    label: "Выполнен",
    value: "delivered",
  },
]
