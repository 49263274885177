import React from "react"

const Breadcrumbs = ({ data }) => (
  <div className="text-sm mb-1">
    {data.map(({ path, label }) => (
      <a className="text-gray-600 hover:underline" href={path}>
        {label} /{" "}
      </a>
    ))}
  </div>
)

export default Breadcrumbs
