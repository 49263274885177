import React, { createContext } from "react"
import { get } from "helpers/network"
import { setLS, getLS } from "helpers/utils"
import withPromise from "hoc/withPromise"
import withContext from "hoc/withContext"

export const MeContext = createContext({})

export const MeProvider = withPromise(
  ({ data, children }) => (
    <MeContext.Provider value={data || getLS("me", {})}>
      {children}
    </MeContext.Provider>
  ),
  () =>
    get("/me", { dontHandleErrors: true })
      .then(me => ({ ...me, auth: true }))
      .catch(e => ({ auth: false, autherror: true }))
      .then(me => {
        setLS("me", me)
        return { ...me, resolved: true }
      })
)

export const withMeProvider = C => props =>
  (
    <MeProvider>
      <C {...props} />
    </MeProvider>
  )

export const withMe = C =>
  withContext(
    ({ context, ...props }) => <C me={context} {...props} />,
    MeContext
  )
