import React, { useEffect } from "react"

const withTitle = (C, title) => props => {
  useEffect(() => {
    document.title = title || document.title
  }, [])
  return <C {...props} />
}

export default withTitle
