import React from "react"
import classnames from "classnames"
import { BiLoaderAlt } from "react-icons/bi"

const F = ({ v, children }) =>
  v ? (
    <div className="h-screen w-full flex justify-center items-center">
      {children}
    </div>
  ) : (
    <>{children}</>
  )

const Loading = ({ className, fullscreen }) => (
  <F v={fullscreen}>
    <BiLoaderAlt
      className={classnames(
        "animate-spin",
        { "h-6 w-6": fullscreen },
        className
      )}
    />
  </F>
)

export default Loading
