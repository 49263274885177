import React from "react"
import RoleFilter from "components/role-filter"
import withRouter from "hoc/withRouter"
import { menu, appName } from "config"
import Link from "components/link"

const VerticalNavigation = () => (
  <>
    <nav className="hidden md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6 pl-4">
      <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
        {/* Toggler */}
        <button
          className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
          type="button"
          onClick={() => "bg-white m-2 py-3 px-6"}
        >
          <i className="fas fa-bars"></i>
        </button>
        {/* Brand */}
        <Link
          to="/"
          clearClass
          className="md:block text-left text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold px-0 pb-4 pt-2 ml-2"
        >
          {appName}
        </Link>
        {/* User */}
        <ul className="md:hidden items-center flex flex-wrap list-none">
          <li className="inline-block relative">
            {/* <NotificationDropdown /> */}
          </li>
          <li className="inline-block relative">{/* <UserDropdown /> */}</li>
        </ul>
        {/* Collapse */}
        <div
          className={
            "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
          }
        >
          {/* Collapse header */}
          <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
            <div className="flex flex-wrap">
              <div className="w-6/12">
                <Link
                  to="/"
                  clearClass
                  className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                ></Link>
              </div>
              <div className="w-6/12 flex justify-end">
                <button
                  type="button"
                  className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                  onClick={() => "hidden"}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>
          {/* Form */}
          <form className="mt-6 mb-4 md:hidden">
            <div className="mb-3 pt-0"></div>
          </form>

          {/* Divider */}
          <hr className="mt-0 mb-3 md:min-w-full" />
          {/* Heading
          <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
            Admin Layout Pages
          </h6>
          */}

          <ul className="md:flex-col md:min-w-full flex flex-col list-none">
            {menu.map((item, i) => (
              <RoleFilter role={item.role} key={i}>
                <li className="items-center">
                  <Link
                    to={item.to}
                    clearClass
                    className="text-gray-600 text-xs uppercase py-3 font-bold block rounded-md hover:bg-gray-100 flex items-center pl-2"
                  >
                    <item.icon className="mr-2 text-sm" />
                    {item.name}
                  </Link>
                </li>
              </RoleFilter>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  </>
)

export default withRouter(VerticalNavigation)
