import * as FP from "lodash/fp"

export const mobile = () => window.innerWidth < 768

// http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
export const hash = s => {
  let hash = 0,
    i,
    chr
  if (!s) return hash
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

// local storage
export const getLS = (k, dv) =>
  (typeof window !== "undefined" &&
    window.localStorage &&
    JSON.parse(window.localStorage?.getItem(k) || null)) ||
  dv
export const setLS = (k, v) =>
  typeof window !== "undefined" &&
  window.localStorage &&
  window.localStorage?.setItem(k, JSON.stringify(v))

// validator mixins
export const file = _ => yup.object().shape({ id: yup.number() })

// breadcrumbs
export const current = (p, index, a) => a.slice(0, index + 1).join("/")
export const id = (p, index, a, x) =>
  p === "new" ? [] : [{ text: `#${p}`, to: current(p, index, a) }]

// unflatten
export const unflatten = x =>
  FP.compose(
    FP.reduce((acc, [k, v]) => FP.set(k, v, acc), FP.isArray(x) ? [] : {}),
    FP.toPairs
  )(x)

// chunk an array
export const chunk = (array, size = 1) =>
  !array || !array?.length
    ? []
    : [array.slice(0, size)].concat(
        chunk(array.slice(size, array.length), size)
      )

// ["admin"] => admin
export const primaryRole = roles =>
  (roles || ["_"]).find(f => f !== "user").toLowerCase()

export const numberFormatter = (number, fixed = 2) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1.0e6
    ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(number)) >= 1.0e3
    ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + "K"
    : Math.abs(Number(number)).toFixed(fixed)
}
