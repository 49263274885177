import React from "react"
import VerticalNavigation from "components/vertical-navigation"
import Breadcrumbs from "components/breadcrumbs"
import Navbar from "components/navbar"

const Dashboard = ({ children, breadcrumbs }) => (
  <>
    <VerticalNavigation />
    <div className="relative md:ml-64">
      <Navbar />
      <div className="px-4 md:px-10 mx-auto container">
        {breadcrumbs && <Breadcrumbs data={breadcrumbs} />}
        {children}
      </div>
    </div>
  </>
)

export default Dashboard
