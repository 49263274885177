import React from "react"
import Link from "components/link"
import { withMe } from "context/me"
import Avatar from "components/avatar"
import incId from "helpers/incId"
import withState from "hoc/withState"
import OutsideClickHandler from "react-outside-click-handler"
import { BiUser, BiLogOut } from "react-icons/bi"

const NavbarDropdown = ({ me, state, ...rest }) =>
  me.auth ? (
    <div className="nav-item dropdown ml-auto relative py-1">
      <div id={`nav-dropdown-${incId()}`} onClick={() => state({ open: true })}>
        <Avatar />
      </div>
      {state().open && (
        <OutsideClickHandler
          onOutsideClick={() => {
            state({ open: !state().open })
          }}
        >
          <div
            className="dropdown-menu dropdown-menu-right shadow absolute right-0 bg-white rounded-md flex flex-col p-2 z-10"
            aria-labelledby={`nav-dropdown-${incId() - 1}`}
          >
            <a
              className="dropdown-item inline-flex items-center w-full px-2 py-1 text-sm transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200 text-black"
              href="/profile"
            >
              <BiUser className="mr-2" />
              Страница&nbsp;профиля
            </a>
            <hr className="my-1" />
            <a
              className="dropdown-item inline-flex items-center w-full px-2 py-1 text-sm transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200 text-black"
              href="/security/logout"
            >
              <BiLogOut className="mr-2" />
              Выйти
            </a>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  ) : (
    <div className="nav-item ml-auto">
      <Link href="/security/login">Войти</Link>
    </div>
  )

export default withMe(withState(NavbarDropdown))
