import React, { Suspense, lazy } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { withMeProvider } from "context/me"
import { appName } from "config"
import routes from "./routes.js"
import withTitle from "hoc/withTitle"
import Error404 from "components/404"
import CardLayout from "components/card-layout"
import Loading from "components/loading"
import DashboardLayout from "components/dashboard-layout"

const App = props => (
  <Suspense fallback={<Loading fullscreen />}>
    <Router>
      <Switch>
        {routes.map(route => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            component={
              /*withTitle(
              lazy(route.src),
              route.title ? `${route.title} | ${appName}` : appName
            )*/ lazy(route.src)
            }
          />
        ))}
        <Route>
          <CardLayout>
            <Error404 />
          </CardLayout>
        </Route>
      </Switch>
    </Router>
  </Suspense>
)

export default withTitle(App, appName)
