import React from "react"
import { Link as _Link } from "react-router-dom"
import classnames from "classnames"

const Link = ({ children, to, className, target, clearClass }) => (
  <_Link
    to={to}
    target={target}
    className={classnames(className, {
      "text-primary-600 hover:underline": !clearClass,
    })}
  >
    {children}
  </_Link>
)

export default Link
