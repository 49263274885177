import React, { useState, useEffect } from "react"
//import ErrorPage from "components/error-page"

const withPromise = (C, fn, noCatch) => props => {
  const [effect, setEffect] = useState()
  const [error, setError] = useState()
  useEffect(() => {
    ;(async () => {
      setEffect(
        noCatch ? await fn(props) : await fn(props)?.catch(e => setError(e))
      )
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])
  return error ? (
    <p>{error?.errorCode || error?.status || 404}</p>
  ) : (
    <C {...props} data={effect} />
  )
}

export const all = (...a) => props =>
  Promise.all(a.map(f => f(props))).catch(e => {
    e = Array.isArray(e) ? e : [e]
    let y = e.find(x => x?.status)
    if (y) throw y
    else throw e
  })

export const res = x => new Promise(r => r(x))

export default withPromise
