/*
 *  Transport utilities
 *
 */
//import { unflatten } from "./utils"

const jsonHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
}

const fdHeaders = {
  Accept: "application/json",
}

const init = {
  credentials: "same-origin",
  redirect: "follow",
}

// basic request
const request = (url, method, body, opts = {}) =>
  fetch(
    `/api${url}`,
    body instanceof FormData
      ? { method, ...init, headers: fdHeaders, body, ...opts }
      : {
          method,
          ...init,
          headers: jsonHeaders,
          body: typeof body === "object" ? JSON.stringify(body) : body,
          ...opts,
        }
  )
    // resolve status and payload in one object
    .then(async res => {
      let data = await res.json()
      return { status: res.status, data }
    })
    // parse property path
    //.then(res => unflatten(res))
    // reject on erroneous status
    .then(({ status, data }) => {
      if (status >= 400) {
        if (!opts.dontHandleErrors) {
          if (
            status === 401 ||
            data?.errorCode === 1 /* Authentication Required */
          ) {
            window.location.replace(
              `/security/login?to=${encodeURIComponent(
                window.location.pathname
              )}`
            )
            return
          }
          //if ([403, 404, 500].includes(status)) window.location.replace(`/error/${status}/`);
        }
        throw { ...data, status }
      } else {
        return data && data.result
      }
    })

const get = (url, opts = {}) => request(url, "GET", undefined, opts)
const post = (url, body, opts = {}) => request(url, "POST", body, opts)
const put = (url, body, opts = {}) => request(url, "PUT", body, opts)

const download = url =>
  fetch(`/api${url}`, {
    method: "GET",
    init,
    headers: { "Content-Type": "application/json" },
  })
    .then(res => {
      if (res.status >= 400) {
        throw res.status
      }
      return res.blob()
    })
    .then(blob => URL.createObjectURL(blob))
    .then(url => window.location.replace(url))

export { get, post, put, request, download }
