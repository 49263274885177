import React from "react"
import { useHistory, useParams, useLocation } from "react-router-dom"
import queryString from "query-string"

const withRouter = C => props => {
  const history = useHistory()
  const query = useParams()
  const l = useLocation()
  const qs = queryString.parse(l.search)
  return (
    <C
      router={{ query, push: url => history.push(url) }}
      query={{ ...qs, ...query }}
      {...props}
    />
  )
}

export default withRouter
