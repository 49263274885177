import DashboardLayout from "components/dashboard-layout"

const routes = [
  {
    path: "/security/registration/confirm",
    src: () => import("pages/security/registration/confirm"),
    title: "Подтверждение регистрации",
  },
  {
    path: "/security/registration",
    src: () => import("pages/security/registration"),
    title: "Регистрация",
  },
  {
    path: "/security/login",
    src: () => import("pages/security/login"),
    title: "Вход в личный кабинет",
  },
  {
    path: "/security/logout",
    src: () => import("pages/security/logout"),
    title: "Выход",
  },
  {
    path: "/security/reset",
    title: "Восстановление пароля",
  },
  {
    path: "/oauth/auth/confirm",
    title: "OAuth authorization confirmation",
  },
  {
    path: "/oauth/auth",
    title: "OAuth authorization",
  },
  {
    path: "/profile",
    src: () => import("pages/profile"),
    title: "Профиль",
  },
  {
    path: "/admin/users/:id",
    src: "/admin/users/form",
    src: () => import("pages/admin/users/form"),
    title: "Пользователь",
  },
  {
    path: "/admin/users",
    src: () => import("pages/admin/users"),
    title: "Пользователи",
  },
  {
    path: `/:role/briefs/:id/creatives/:creative_id`,
    src: () => import("pages/default/briefs/creatives/form"),
    title: "Креатив",
  },
  {
    path: `/:role/briefs/:id`,
    src: () => import("pages/default/briefs/form"),
    title: "Бриф",
  },
  {
    path: `/:role/briefs`,
    src: () => import("pages/default/briefs"),
    title: "Брифы",
  },
  {
    path: "/",
    exact: true,
    src: () => import("pages"),
  },
]

export default routes
